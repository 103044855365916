import { modifier } from 'ember-modifier';
//@ts-expect-error
import Sortable from 'sortablejs';

// NOTE: I’ve left commented out code in here to serve as SortableJS documentation

interface Event {
  to: HTMLElement; //— list, in which moved element
  from: HTMLElement; // — previous list
  item: HTMLElement; //— dragged element
  clone: HTMLElement;
  oldIndex: number; // | undefined; //— old index within parent
  newIndex: number; // | undefined; //— new index within parent
  oldDraggableIndex: number; // | undefined; //— old index within parent, only counting draggable elements
  newDraggableIndex: number; // | undefined; //— new index within parent, only counting draggable elements
  pullMode: string | boolean | undefined; //— Pull mode if dragging into another sortable ("clone", true, or false), otherwise undefined
}

interface MoveEvent {
  to: HTMLElement;
  from: HTMLElement;
  dragged: HTMLElement;
  draggedRect: DOMRect;
  related: HTMLElement; //— element on which have guided
  relatedRect: DOMRect;
  willInsertAfter: Boolean; //— true if will element be inserted after target (or false if before)
}

export interface SortableSignature {
  Element: HTMLElement;
  Args: {
    Positional: [];
    Named: {
      sort?: boolean;
      draggable: string;
      handle?: string;
      group?: string | { name: string; pull: boolean | 'clone' };
      swapThreshold?: number;
      allowAdd?: string;
      allowRemove?: string;
      removeItem?: boolean;
      onChange?: (oldIndex: number, newIndex: number) => void;
      onMove?: (
        fromElement: HTMLElement,
        fromIndex: number,
        toElement: HTMLElement,
        toIndex: number,
      ) => void;
    };
  };
}

const sortable = modifier<SortableSignature>(function sortable(
  element: HTMLElement,
  _,
  {
    sort,
    draggable,
    handle,
    group,
    swapThreshold,
    allowAdd,
    allowRemove,
    removeItem,
    onChange,
    onMove,
  }: SortableSignature['Args']['Named'],
) {
  const sortable = new Sortable(element, {
    sort: sort ?? true,
    draggable: draggable,
    handle: handle,
    group: group,
    swapThreshold: swapThreshold ?? 1,
    // setData: function (dataTransfer: DataTransfer, dragEl: HTMLElement) {
    //   dataTransfer.setData('Text', dragEl.textContent ?? ''); // `dataTransfer` object of HTML5 DragEvent
    // },

    // // Element is chosen
    onChoose: function (/*evt: Event*/) {
      //   evt.oldIndex; // element index within parent
    },

    // // Element is unchosen
    onUnchoose: function (/*evt: Event*/) {
      // same properties as onEnd
    },

    // // Element dragging started
    onStart: function (/*evt: Event*/) {
      //   evt.oldIndex; // element index within parent
    },

    // Element dragging ended
    onEnd: function (evt: Event) {
      // evt.item; // dragged HTMLElement
      // evt.to; // target list
      // evt.from; // previous list
      // evt.oldIndex; // element's old index within old parent
      // evt.newIndex; // element's new index within new parent
      // evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
      // evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
      // evt.clone; // the clone element
      // evt.pullMode; // when item is in another sortable: `"clone"` if cloning, `true` if moving
      if (evt.from === evt.to) {
        if (evt.oldDraggableIndex !== evt.newDraggableIndex) {
          if (removeItem) {
            evt.item.remove();
          }
          onChange?.(evt.oldDraggableIndex, evt.newDraggableIndex);
        }
      } else {
        if (evt.pullMode === 'clone') {
          // From my testing evt.pullMode is true not a string?!?!?!
          evt.item.remove();
        } else {
          const child = evt.from.children[evt.oldDraggableIndex];
          if (child) {
            evt.from.insertBefore(evt.item, child);
          } else {
            evt.from.appendChild(evt.item);
          }
          if (removeItem) {
            evt.item.remove();
          }
        }
        onMove?.(evt.from, evt.oldIndex, evt.to, evt.newIndex);
      }
    },

    // // Element is dropped into the list from another list
    onAdd: function (/*evt: Event*/) {
      // same properties as onEnd
    },

    // // Changed sorting within list
    onUpdate: function (/*evt: Event*/) {
      // same properties as onEnd
    },

    // // Called by any change to the list (add / update / remove)
    onSort: function (/*evt: Event*/) {
      // same properties as onEnd
    },

    // // Element is removed from the list into another list
    onRemove: function (/*evt: Event*/) {
      // same properties as onEnd
    },

    // // Attempt to drag a filtered element
    onFilter: function (/*evt: Event*/) {
      // var itemEl = evt.item; // HTMLElement receiving the `mousedown|tapstart` event.
    },

    // // Event when you move an item in the list or between lists
    onMove: function (evt: MoveEvent /*, originalEvent: Event*/) {
      //   // Example: https://jsbin.com/nawahef/edit?js,output
      //   evt.dragged; // dragged HTMLElement
      //   evt.draggedRect; // DOMRect {left, top, right, bottom}
      //   evt.related; // HTMLElement on which have guided
      //   evt.relatedRect; // DOMRect
      //   evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
      //   //@ts-expect-error
      //   originalEvent.clientY; // mouse position
      //   // return false; — for cancel
      //   // return -1; — insert before target
      //   // return 1; — insert after target
      //   // return true; — keep default insertion point based on the direction
      //   // return void; — keep default insertion point based on the direction
      if (allowAdd && !evt.to.matches(allowAdd)) {
        return false;
      }
    },

    // // Called when creating a clone of element
    onClone: function (/*evt: Event*/) {
      // var origEl = evt.item;
      // var cloneEl = evt.clone;
    },

    // // Called when dragging element changes position
    onChange: function (/*evt: Event*/) {
      //   evt.newIndex; // most likely why this event is used is to get the dragging element's current index
      // same properties as onEnd
    },
  });

  return () => {
    sortable.destroy();
  };
});

export default sortable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    sortable: typeof sortable;
  }
}
